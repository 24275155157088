import {BrowserRouter as Router, Route, Redirect, Switch, Link} from 'react-router-dom';
import React, {Suspense, lazy, Fragment} from 'react';
import Loader from 'react-loaders'

import {
    ToastContainer,
} from 'react-toastify';
import {Alert, Button, Col, Form, FormGroup, Input, Row} from "reactstrap";


const Voucher = lazy(() => import('../../www/Voucher'));
const ImportVoucherCode = lazy(() => import('../../www/ImportVoucherCode'));
const Auth = lazy(() => import('../../www/Auth'));

export const fakeAuth = {
    isAuthenticated: localStorage.getItem('email'),
    authenticate(cb) {
        localStorage.setItem('email', global.email);
        this.isAuthenticated = localStorage.getItem('email')
    },
    signout(cb) {
        localStorage.clear()
        this.isAuthenticated = null
    }
};

const PrivateRoute = ({component: Component, ...rest}) => (
    <Route {...rest} render={(props) => (
        fakeAuth.isAuthenticated !== null
            ? <Component {...props} />
            : <Redirect to={{
                pathname: '/login',
                state: {from: props.location}
            }}/>
    )}/>
);

export const Page404 = ({location}) => (
    <div className="h-100 bg-animation">
        <div className="d-flex h-100 justify-content-center align-items-center">
            <Col md="8" className="mx-auto app-login-box">
                <div className="modal-dialog w-100 mx-auto">
                    <div className="modal-content">
                        <div className="modal-body">
                            <div className="h5 modal-title text-center">
                                <div className="mt-2">
                                    <h2 style={{fontFamily: 'fantasy'}}>404</h2>
                                    <h6>Mohon maaf, halaman yang Anda cari tidak ditemukan.
                                        Kemungkinan halaman dihapus atau Anda salah menuliskan URL.
                                    </h6>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer clearfix">
                            <div className="float-left">
                                <span>Silakan kembali ke Login</span>
                            </div>
                            <div className="float-right">
                                <Link className="mr-2 btn-icon btn-icon-only btn btn-primary" to='/login'><i
                                    className='lnr-home'></i></Link>
                            </div>
                        </div>
                    </div>
                </div>
            </Col>
        </div>
    </div>
);
const AppMain = () => {

    return (
        <Fragment>

            <Suspense fallback={
                <div className="loader-container">
                    <div className="loader-container-inner">
                        <div className="text-center">
                            <Loader type="ball-pulse-rise"/>
                        </div>

                    </div>
                </div>
            }>
                <Switch>
                    <Route exact path="/login" component={Auth}/>

                    <PrivateRoute path="/voucher" component={Voucher}/>
                    <PrivateRoute path="/import" component={ImportVoucherCode}/>


                    <Route component={Page404}/>
                </Switch>

            </Suspense>

            <Route exact path="/" render={() => (
                <Redirect to="/login"/>
            )}/>
            <ToastContainer/>
        </Fragment>
    )
};

export default AppMain;
