import './polyfills'
import React from 'react';
import ReactDOM from 'react-dom';

import {HashRouter} from 'react-router-dom';
import './assets/base.scss';
import Main from './Main';
import configureStore from './config/configureStore';
import {Provider} from 'react-redux';

const store = configureStore();
const rootElement = document.getElementById('root');

// global.global_id = localStorage.getItem('user');
// let items = [{id:'123123123123',email:'wan@mail.com'},{id:'2941cfac260d4ab9be394e5050f53088',email:'mohd@mail.com'},{id:'2dd90868ba2f4fb7a2d2c04936bc294c',email:'iqbal@mail.com'},
//     {id:'c18aad8ce72240df93a68a3eec318682',email:'aideed@mail.com'}]
// var item = items[Math.floor(Math.random() * items.length)];

global.email = localStorage.getItem('email');

if (window.location.hostname !== "localhost") {
    global.ipServer = window.location.protocol + "//" + window.location.hostname+ "/voucher/";

} else {
    global.ipServer = ' http://192.168.5.97:8080/';   /*Logic*/
}

const renderApp = Component => {
    ReactDOM.render(
        <Provider store={store}>
            <HashRouter>
                <Component/>
            </HashRouter>
        </Provider>,
        rootElement
    );
};

renderApp(Main);

if (module.hot) {
    module.hot.accept('./Main', () => {
        const NextApp = require('./Main').default;
        renderApp(NextApp);
    });
}
